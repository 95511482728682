var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jobauth"},[_c('mt-header',{attrs:{"title":_vm.$t('workInfo')}},[_c('router-link',{attrs:{"slot":"left","to":""},slot:"left"},[_c('mt-button',{on:{"click":function($event){return _vm.$router.back(-1)}}},[_c('img',{attrs:{"slot":"icon","src":require("@/assets/back.png"),"height":"20","width":"20"},slot:"icon"}),_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1),_c('call-phone',{attrs:{"slot":"right"},slot:"right"})],1),_c('step-item',{attrs:{"step":2}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('industry')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('industryPlaceholder'),"readonly":"","state":_vm.industryCodeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.industry, (e) => {
          _vm.form.industryCode = e.code;
          _vm.BEHAVIOR_ADD({
            id: 'P03_C01_S_INDUSTRY',
            newValue: _vm.form.industryCode
          })
        })}},model:{value:((_vm.Enum.industry.filter(e => e.code == _vm.form.industryCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.industry.filter(e => e.code == _vm.form.industryCode)[0]||{}), "name", $$v)},expression:"(Enum.industry.filter(e => e.code == form.industryCode)[0]||{}).name"}},[(_vm.Enum.industryLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWork),expression:"isWork"}]},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('occupationType')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('occupationTypePlaceholder'),"readonly":"","state":_vm.jobTypeCodeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.jobType, (e) => {
            _vm.form.jobTypeCode = e.code;
            _vm.BEHAVIOR_ADD({
              id: 'P03_C02_S_JOBTYPE',
              newValue: _vm.form.jobTypeCode
            })
          })}},model:{value:((_vm.Enum.jobType.filter(e => e.code == _vm.form.jobTypeCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.jobType.filter(e => e.code == _vm.form.jobTypeCode)[0]||{}), "name", $$v)},expression:"(Enum.jobType.filter(e => e.code == form.jobTypeCode)[0]||{}).name"}},[(_vm.Enum.jobTypeLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('averageMonthlyIncome')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('averageMonthlyIncomePlaceholder'),"readonly":"","state":_vm.monthlyIncomeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.monthlyIncome, (e) => {
            _vm.form.monthlyIncome = e.code;
            _vm.BEHAVIOR_ADD({
              id: 'P03_C03_S_MONTHLYINCOME',
              newValue: _vm.form.monthlyIncome
            })
          })}},model:{value:((_vm.Enum.monthlyIncome.filter(e => e.code == _vm.form.monthlyIncome)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.monthlyIncome.filter(e => e.code == _vm.form.monthlyIncome)[0]||{}), "name", $$v)},expression:"(Enum.monthlyIncome.filter(e => e.code == form.monthlyIncome)[0]||{}).name"}},[(_vm.Enum.monthlyIncomeLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('paydayType')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('paydayTypePlaceholder'),"readonly":"","state":_vm.paydayTypeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.paydayType, (e) => {
            _vm.form.payCycle = e.code;
            _vm.BEHAVIOR_ADD({
              id: 'P03_C04_I_PAYCYCLE',
              newValue: _vm.form.payCycle
            })
          })}},model:{value:((_vm.Enum.paydayType.filter(e => e.code == _vm.form.payCycle)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.paydayType.filter(e => e.code == _vm.form.payCycle)[0]||{}), "name", $$v)},expression:"(Enum.paydayType.filter(e => e.code == form.payCycle)[0]||{}).name"}},[(_vm.Enum.paydayTypeLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('payday')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('paydayPlaceholder'),"readonly":"","state":_vm.paydayState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.payday, (e) => {
            _vm.form.payDay = e.code;
            _vm.BEHAVIOR_ADD({
              id: 'P03_C05_I_PAYDAY',
              newValue: _vm.form.payDay
            })
          })}},model:{value:((_vm.Enum.payday.filter(e => e.code == _vm.form.payDay)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.payday.filter(e => e.code == _vm.form.payDay)[0]||{}), "name", $$v)},expression:"(Enum.payday.filter(e => e.code == form.payDay)[0]||{}).name"}},[(_vm.Enum.paydayLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('companyName')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('companyNamePlaceholder'),"state":_vm.companyState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P03_C06_I_COMPANY',
            newValue: _vm.form.company
          })}},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title optional"},[_vm._v(_vm._s(_vm.$t('workPhone')))]),_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### #### ###'),expression:"'#### #### #### ###'"}],attrs:{"placeholder":_vm.$t('workPhonePlaceholder'),"type":"tel","state":_vm.maskedCompanyPhoneState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P03_C07_I_COMPANYPHONE',
            newValue: _vm.form.maskedCompanyPhone
          })}},model:{value:(_vm.form.maskedCompanyPhone),callback:function ($$v) {_vm.$set(_vm.form, "maskedCompanyPhone", $$v)},expression:"form.maskedCompanyPhone"}})],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('companyAddressSelect1')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('companyAddressPlaceholder'),"readonly":"","state":_vm.companyAddrProvinceCodeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.prov, (e) => {
            _vm.form.companyAddrProvinceCode = e.code;
            _vm.BEHAVIOR_ADD({
              id: 'P03_C08_S_COMPANYPROVINCE',
              newValue: _vm.form.companyAddrProvinceCode
            })
          })}},model:{value:((_vm.Enum.prov.filter(e => e.code == _vm.form.companyAddrProvinceCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.prov.filter(e => e.code == _vm.form.companyAddrProvinceCode)[0]||{}), "name", $$v)},expression:"(Enum.prov.filter(e => e.code == form.companyAddrProvinceCode)[0]||{}).name"}},[(_vm.Enum.provLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('companyAddressSelect2')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('companyAddressPlaceholder'),"readonly":"","state":_vm.companyAddrCityCodeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.city, (e) => {
            _vm.form.companyAddrCityCode = e.code;
            _vm.BEHAVIOR_ADD({
              id: 'P03_C09_S_COMPANYCITY',
              newValue: _vm.form.companyAddrCityCode
            })
          })}},model:{value:((_vm.Enum.city.filter(e => e.code == _vm.form.companyAddrCityCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.city.filter(e => e.code == _vm.form.companyAddrCityCode)[0]||{}), "name", $$v)},expression:"(Enum.city.filter(e => e.code == form.companyAddrCityCode)[0]||{}).name"}},[(_vm.Enum.cityLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('detailedCompanyAddress')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('detailedCompanyAddressPlaceholder'),"state":_vm.companyAddrDetailState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P03_C10_S_MOBILETYPE',
            newValue: _vm.form.companyAddrDetail
          })}},model:{value:(_vm.form.companyAddrDetail),callback:function ($$v) {_vm.$set(_vm.form, "companyAddrDetail", $$v)},expression:"form.companyAddrDetail"}})],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('companyBarangay')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('companyBarangayPlaceholder'),"state":_vm.companyBarangayState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P03_C11_S_MOBILETYPE',
            newValue: _vm.form.companyBarangay
          })}},model:{value:(_vm.form.companyBarangay),callback:function ($$v) {_vm.$set(_vm.form, "companyBarangay", $$v)},expression:"form.companyBarangay"}})],1)])]),_c('div',{staticClass:"bottom"},[_c('mt-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("nextStep")))])],1),_c('popup-picker',{ref:"popupPicker"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }